export default {
  name: "koreanFormat",
  methods: {
    dateTimeFormat(d) {
        if (!d) return;
        let date = new Date(d);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? "오후" : "오전";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        let strDate = date.getFullYear() + "년 " + (date.getMonth() + 1) + "월 " + date.getDate() + "일";
        let strTime = ampm + " " + hours + ":" + minutes;
        return {
          date: strDate,
          time: strTime,
        };
      },
  }
}