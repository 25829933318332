import { createRouter, createWebHistory } from 'vue-router';

function guest(to, from, next) {
    if (localStorage.token) {
        next({ name: 'User' });
    } else next();
}

function guard(to, from, next) {
    if (localStorage.token) {
        next();
    } else {
        next({ name: 'login' });
    }
}

const routes = [
    {
        path: '/',
        name: 'loginpage',
        exact: true,
        component: () => import('./pages/Dashboard/dashboard.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'loginpage', label: 'loginpage' }],
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        exact: true,
        component: () => import('./pages/Dashboard/dashboard.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'dashboard' }],
        },
    },

    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('./pages/password/changePassword.vue'),
        beforeEnter: guard,
    },

    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
        },
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Input' }],
        },
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        },
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Invalid State' }],
        },
    },
    {
        path: '/button',
        name: 'button',
        component: () => import('./components/ButtonDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Button' }],
        },
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Table' }],
        },
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'List' }],
        },
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Tree' }],
        },
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Panel' }],
        },
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlayDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Overlay' }],
        },
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Media' }],
        },
    },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Messages' }],
        },
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
        },
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Charts' }],
        },
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'Misc' }],
        },
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./utilities/Icons.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Icons' }],
        },
    },
    {
        path: '/widgets',
        name: 'widgets',
        component: () => import('./utilities/Widgets.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Widgets' }],
        },
    },
    {
        path: '/grid',
        name: 'grid',
        component: () => import('./utilities/GridDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Grid System' }],
        },
    },
    {
        path: '/spacing',
        name: 'spacing',
        component: () => import('./utilities/SpacingDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Spacing' }],
        },
    },
    {
        path: '/elevation',
        name: 'elevation',
        component: () => import('./utilities/ElevationDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Elevation' }],
        },
    },
    {
        path: '/typography',
        name: 'typography',
        component: () => import('./utilities/Typography.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Typography' }],
        },
    },
    {
        path: '/display',
        name: 'display',
        component: () => import('./utilities/DisplayDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Display' }],
        },
    },
    {
        path: '/flexbox',
        name: 'flexbox',
        component: () => import('./utilities/FlexBoxDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Flexbox' }],
        },
    },
    {
        path: '/text',
        name: 'text',
        component: () => import('./utilities/TextDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Utilities', label: 'Text' }],
        },
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Crud' }],
        },
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./pages/CalendarDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Calendar' }],
        },
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Timeline' }],
        },
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('./pages/Invoice.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Invoice' }],
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('./pages/Help.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Help' }],
        },
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }],
        },
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Documentation' }],
        },
    },
    {
        path: '/AutoLogout',
        name: 'AutoLogout',
        component: () => import('./components/AutoLogout.vue'),
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'AutoLogout' }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
        beforeEnter: guest,
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: () => import('./pages/forgotpassword.vue'),
        beforeEnter: guest,
    },
    {
        path: '/OtpEnter',
        name: 'OtpEnter',
        component: () => import('./pages/OtpEnter.vue'),
        beforeEnter: guest,
    },
    {
        path: '/changepassword',
        name: 'changepassword',
        component: () => import('./pages/changepassword.vue'),
        beforeEnter: guest,
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
    },
    {
        path: '/cms',
        name: 'Cms',
        component: () => import('./pages/cms/Cms.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Cms' }],
        },
    },

    {
        path: '/faq',
        name: 'Faq',
        component: () => import('./pages/faq/FaqList.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Faq' }],
        },
    },

    {
        path: '/faq/edit/:id',
        name: 'Faqedit',
        component: () => import('./pages/faq/EditFaq.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'EditFaq' }],
        },
    },
    {
        path: '/faq/view/:id',
        name: 'Faqview',
        component: () => import('./pages/faq/ViewFaq.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'ViewFaq' }],
        },
    },

    {
        path: '/faq/add',
        name: 'Faqadd',
        component: () => import('./pages/faq/AddFaq.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Faqadd' }],
        },
    },
    {
        path: '/cms/add',
        name: 'Cmsadd',
        component: () => import('./pages/cms/AddCms.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Add cms' }],
        },
    },
    {
        path: '/cms/view/:id',
        name: 'ViewCms',
        exact: true,
        component: () => import('./pages/cms/ViewCms.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Faq', label: 'View Cms' }],
        },
    },
    {
        path: '/cms/edit/:id',
        name: 'Cmsedit',
        component: () => import('./pages/cms/EditCms.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'Pages', label: 'Cmsedit' }],
        },
    },

    // Asian history dashboard start

    // {
    //     path: '/dashboard',
    //     name: 'dashboard',
    //     component: () => import('./pages/Dashbo'),
    //     beforeEnter: guard,
    //     meta: {
    //         breadcrumb: [{ parent: 'Pages', label: 'dashboard' }],
    //     },
    // },



    // Asian history dashboard end

    // Asian history Account Info start

    {
        path: '/account-info',
        name: 'AccountInfo',
        component: () => import('./pages/AccountManagement/AccontInfo.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'accountinfo' }],
        },
    },
    {
        path: '/change-pass',
        name: 'ChangePass',
        component: () => import('./pages/AccountManagement/ChangePass.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ChangePass' }],
        },
    },
    {
        path: '/modify-info',
        name: 'EditInfo',
        component: () => import('./pages/AccountManagement/EditInfo.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'EditInfo' }],
        },
    },

    // Asian history Account Info end

    // Asian history ManagerManagement start

    {
        path: '/ManagerManagementlist',
        name: 'ManagerManagementlist',
        component: () => import('./pages/SuperAdmin/ManagerManagement/ManagerManagementlist.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerManagementlist' }],
        },
    },

    {
        path: '/ManagerManagementView/:id',
        name: 'ManagerManagementView',
        component: () => import('./pages/SuperAdmin/ManagerManagement/ManagerManagementView.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerManagementView' }],
        },
    },

    {
        path: '/ManagerManagementEdit/:id',
        name: 'ManagerManagementEdit',
        component: () => import('./pages/SuperAdmin/ManagerManagement/ManagerManagementEdit.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerManagementEdit' }],
        },
    },

    {
        path: '/ManagerManagementAdd',
        name: 'ManagerManagementAdd',
        component: () => import('./pages/SuperAdmin/ManagerManagement/ManagerManagementAdd.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerManagementAdd' }],
        },
    },

    // Asian history ManagerManagement end


    // Asian history Votemanagement start

    {
        path: '/Votemanagementlist',
        name: 'Votemanagementlist',
        component: () => import('./pages/SuperAdmin/Votemanagement/Votemanagementlist.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'Votemanagementlist' }],
        },
    },

    {
        path: '/VotemanagementAdd',
        name: 'VotemanagementAdd',
        component: () => import('./pages/SuperAdmin/Votemanagement/VotemanagementAdd.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'VotemanagementAdd' }],
        },
    },
    {
        path: '/VoteManagementDetails/:id',
        name: 'VoteManagementDetails',
        component: () => import('./pages/SuperAdmin/Votemanagement/VoteManagementDetails.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'VoteManagementDetails' }],
        },
    },
    {
        path: '/VoteManagementEdit/:id',
        name: 'VoteManagementEdit',
        component: () => import('./pages/SuperAdmin/Votemanagement/VoteManagementEdit.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'VoteManagementEdit' }],
        },
    },
    {
        path: '/ViewApplicants/:id',
        name: 'ViewApplicants',
        component: () => import('./pages/SuperAdmin/Votemanagement/ViewApplicants.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ViewApplicants' }],
        },
    },


    // Asian history Votemanagement end

    // Asian history Manager Votemanagement start

    {
        path: '/ManagerApplicationlist',
        name: 'ManagerApplicationlist',
        component: () => import('./pages/Manager/ApplicationManagement/ManagerApplicationlist.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerApplicationlist' }],
        },
    },

    {
        path: '/ManagerApplicationlistView/:id',
        name: 'ManagerApplicationlistView',
        component: () => import('./pages/Manager/ApplicationManagement/ManagerApplicationlistView.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerApplicationlistView' }],
        },
    },
    {
        path: '/ManagerApplicationtDetails/:id',
        name: 'ManagerApplicationtDetails',
        component: () => import('./pages/Manager/ApplicationManagement/ManagerApplicationtDetails.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerApplicationtDetails' }],
        },
    },


    // Asian history Manager Votemanagement end


    // Asian history BulletinManagement start

    {
        path: '/BulletinManagementList',
        name: 'BulletinManagementList',
        component: () => import('./pages/SuperAdmin/BulletinManagement/BulletinManagementList.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'BulletinManagementList' }],
        },
    },

    {
        path: '/BulletinManagementAdd',
        name: 'BulletinManagementAdd',
        component: () => import('./pages/SuperAdmin/BulletinManagement/BulletinManagementAdd.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'BulletinManagementAdd' }],
        },
    },

    {
        path: '/BulletinManagementView/:id',
        name: 'BulletinManagementView',
        component: () => import('./pages/SuperAdmin/BulletinManagement/BulletinManagementView.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'BulletinManagementView' }],
        },
    },

    {
        path: '/BulletinManagementEdit/:id',
        name: 'BulletinManagementEdit',
        component: () => import('./pages/SuperAdmin/BulletinManagement/BulletinManagementEdit.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'BulletinManagementEdit' }],
        },
    },

    // Asian history BulletinManagement start

    // Asian history BulletinManagement start

    {
        path: '/BulletinCategoryManagementlist',
        name: 'BulletinCategoryManagementlist',
        component: () => import('./pages/SuperAdmin/BulletinCategoryManagement/BulletinCategoryManagementlist.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'BulletinCategoryManagementlist' }],
        },
    },
    {
        path: '/BulletinCategoryManagementview/:id',
        name: 'BulletinCategoryManagementview',
        component: () => import('./pages/SuperAdmin/BulletinCategoryManagement/BulletinCategoryManagementview.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'BulletinCategoryManagementview' }],
        },
    },

    // Asian history BulletinManagement start

    // Asian history FreeBoardManagement start

    {
        path: '/FreeBoardManagementlist',
        name: 'FreeBoardManagementlist',
        component: () => import('./pages/SuperAdmin/FreeBoardManagement/FreeBoardManagementlist.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'FreeBoardManagementlist' }],
        },
    },
    {
        path: '/FreeBoardManagement/ViewFreeBoard/:id',
        name: 'ViewFreeBoard',
        component: () => import('./pages/SuperAdmin/FreeBoardManagement/ViewFreeBoard.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ViewFreeBoard' }],
        },
    },
    {
        path: '/FreeBoardManagement/EditFreeBoard/:id',
        name: 'EditFreeBoard',
        component: () => import('./pages/SuperAdmin/FreeBoardManagement/EditFreeBoard.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'EditFreeBoard' }],
        },
    },
    {
        path: '/FreeBoardManagement/AddFreeBoard',
        name: 'AddFreeBoard',
        component: () => import('./pages/SuperAdmin/FreeBoardManagement/AddFreeBoard.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'AddFreeBoard' }],
        },
    },

    // Asian history FreeBoardManagement end
    // Asian history General User start

    {
        path: '/GeneralUser/GeneralUserList',
        name: 'GeneralUserList',
        component: () => import('./pages/SuperAdmin/GeneralUser/GeneralUserList.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'GeneralUserList' }],
        },
    },
    // Asian history General User end

    // Asian history Model User start

    {
        path: '/ModelUser/ModelUserList',
        name: 'ModelUserList',
        component: () => import('./pages/SuperAdmin/ModelUser/ModelUserList.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ModelUserList' }],
        },
    },
    {
        path: '/ModelUser/ViewModelUser/:id',
        name: 'ViewModelUser',
        component: () => import('./pages/SuperAdmin/ModelUser/ViewModelUser.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ViewModelUser' }],
        },
    },
    // Asian history Model User end



    // Asian history Inquiry start

    {
        path: '/Inquiry/Inquirylist',
        name: 'Inquirylist',
        component: () => import('./pages/SuperAdmin/Inquiry/Inquirylist.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'Inquirylist' }],
        },
    },
    {
        path: '/Inquiry/ViewInquiry/:id',
        name: 'ViewInquiry',
        component: () => import('./pages/SuperAdmin/Inquiry/ViewInquiry.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ViewInquiry' }],
        },
    },
    {
        path: '/Inquiry/InquiryDetails/:id',
        name: 'InquiryDetails',
        component: () => import('./pages/SuperAdmin/Inquiry/InquiryDetails.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'InquiryDetails' }],
        },
    },

    // Asian history Inquiry end

    // Asian history Manager Inquiry start

    {
        path: '/Manager/Inquiry/Inquirylist',
        name: 'ManagerInquirylist',
        component: () => import('./pages/Manager/Inquiry/Inquirylist.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerInquirylist' }],
        },
    },
    {
        path: '/Manager/Inquiry/ViewInquiry/:id',
        name: 'ManagerViewInquiry',
        component: () => import('./pages/Manager/Inquiry/ViewInquiry.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerViewInquiry' }],
        },
    },
    {
        path: '/Manager/Inquiry/InquiryDetails/:id',
        name: 'ManagerInquiryDetails',
        component: () => import('./pages/Manager/Inquiry/InquiryDetails.vue'),
        beforeEnter: guard,
        meta: {
            breadcrumb: [{ parent: 'pages', label: 'ManagerInquiryDetails' }],
        },
    },

    // Asian history Manager Inquiry end



];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

export default router;
