
<!-- <template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <form class="login">
                    <a @click="goDashboard" class="logo">
                        <img src="../assets/layout/images/logo-symbol.svg" alt="ASIAN STORY" />
                        <img src="../assets/layout/images/logo-asian-story_2.svg" alt="ASIAN STORY" />
                        
                    </a>

                    <div class="form-group">
                      
                        <InputText class="p-invalid" id="email" :placeholder="$t('button.emailplaceholder')" v-model="email" />
                        <div class="text-redx">{{ error.email }}</div>
                        <div class="text-redx">{{ email_err }}</div>
                    </div>
                    <div class="form-group">
                       
                        <input type="password" class="p-password" @keyup.enter="Login" id="password" :placeholder="$t('button.passwordplace')" :feedback="false" v-model="password" :class="`${error.password ? 'p-invalid' : ''}`" />
                        <div class="text-redx">{{ error.password }}</div>
                        <div class="text-redx">{{ pass_err }}</div>
                    </div>
                    <Button :label="$t('button.login')" type="button" @click="Login" class="formsubmit"></Button>
                    <a href="/forgotpassword" class="forget-pass">{{$t('password.foget_dfc_pass')}}</a>
                </form>
                
            </div>
        </div>
        <Dialog :header="$t('password.recovery_DFC')" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
            <div class="p-field p-fluid">
                <label for="username">{{ $t('password.p_email') }}:</label>
                <InputText :class="`${error.resetemail ? 'p-invalid' : ''}`" id="username" type="email" v-model="resetemail" />
                <small v-show="helptxt">{{ $t('password.help') }}</small>
                <div class="text-redx">{{ error.resetemail }}{{ errmsg }}</div>
            </div>
            <template #footer>
                <Button :label="$t('password.send_mail')" @click="resetpass" icon="pi pi-check" class="p-button-primary" />
                
            </template>
        </Dialog>
    </div>
</template> -->
<template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <a @click="goDashboard" class="logo">
                    <!-- <img src="../assets/layout/images/logo-symbol.svg" alt="ASIAN STORY" /> -->
                    <img src="../assets/layout/images/logo-asian-story_3.svg" alt="ASIAN STORY" />
                </a>
                <div class="input_group">
                    <InputText id="username" :placeholder="$t('button.emailplaceholder')" v-model="email"
                        :class="`${error.email ? 'p-invalidusername' : ''}`" />

                </div>
                <div class="text-redx">{{ error.email }}</div>
                <div class="text-redx">{{ email_err }}</div>

                <div class="input_group">
                    <Password @keyup.enter="Login" id="password" :placeholder="$t('button.passwordplace')" :feedback="false"
                        v-model="password" :class="`${error.password ? 'p-invalid' : ''}`" />


                    <div class="text-redx">{{ error.password }}</div>
                    <div class="text-redx">{{ pass_err }}</div>
                </div>
                <Button :label="$t('button.login')" type="button" @click="Login" class="colorchange"></Button>
                <router-link :to="'/forgotpassword'">
                    <div class="fpass"><u>{{$t('password.foget_dfc_pass')}}</u></div>
                </router-link>

            </div>
        </div>
        <Dialog :header="$t('password.recovery_DFC')" v-model:visible="display" :breakpoints="{ '960px': '75vw' }"
            :style="{ width: '30vw' }" :modal="true">
            <div class="p-field p-fluid">
                <label for="username">{{ $t('password.p_email') }}:</label>
                <InputText :class="`${error.resetemail ? 'p-invalid' : ''}`" id="username" type="email"
                    v-model="resetemail" />
                <small v-show="helptxt">{{ $t('password.help') }}</small>
                <div class="text-redx">{{ error.resetemail }}{{ errmsg }}</div>
            </div>
            <template #footer>
                <Button :label="$t('password.send_mail')" @click="resetpass" icon="pi pi-check"
                    class="p-button-primary" />
                <!-- <Button label="Dismiss" @click="close" icon="pi pi-pi-times" class="p-button-danger" /> -->
            </template>
        </Dialog>
    </div>

</template>
<script>
import validateLogin from '../validations/validateLogin';
import validateReset from '../validations/validateReset';
import axios from 'axios';
export default {
    data() {
        return {
            errmsg: '',
            helptxt: true,
            resetemail: '',
            display: false,
            email_err: '',
            pass_err: '',
            email: '',
            password: '',
            error: {},
            errors: {},
        };
    },
    methods: {
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        async Login() {
            let credentials = {
                email: this.email,
                password: this.password,
            };
            const { isInvalid, error } = validateLogin(credentials);
            if (isInvalid) {
                this.error = error;
                console.log(error);
            } else {
                var self = this;
                this.error = {};
                // console.log('pass');
                await axios({
                    method: 'post',
                    url: 'asnhist-dev/api/v1/admin/auth/login',
                    data: credentials,
                })
                    .then(function (response) {
                        console.log(response);
                        if (response.data.status === 200) {
                            console.log(response.data.status);
                            // localStorage.removeItem("expirytoken");
                            // this.$router.push({ name: 'dashboard' });
                            localStorage.setItem('token', response.data.data.token);
                            localStorage.setItem('userType', response.data.data.userType);
                            localStorage.setItem('UserEmail', response.data.data.email);
                            localStorage.setItem('UserName', response.data.data.name);
                            localStorage.setItem('UserMobile', response.data.data.mobile);
                            if (response.data.data.userType === 'super_admin') {
                                window.location = '/dashboard';
                            } else if (response.data.data.userType === 'manager') {
                                window.location = '/dashboard';
                            }

                            else {
                                // localStorage.setItem('refcode', response.data.data.referralCode);
                                // localStorage.setItem('dfc_Id', response.data.data.userId);
                                window.location = '/';
                            }
                        }
                    })
                    .catch(function (err) {
                        let text = err.response.data.message;
                        let result = text.match('Password');
                        let result1 = text.match('Email Does Not Exists');
                        console.log(text);
                        if (result == 'Password') {
                            self.pass_err = 'invalid password.';
                            self.email_err = '';
                        } else if (result1 == 'Email Does Not Exists') {
                            self.pass_err = '';
                            self.email_err = 'invalid email.';
                        } else {
                            alert('Email or, password is incorrect');
                        }
                    });
            }
        },
        resetpass() {
            let vcheckData = {
                resetemail: this.resetemail,
            };
            const { isInvalid, error } = validateReset(vcheckData);
            if (isInvalid) {
                this.error = error;
                this.helptxt = false;
                console.log(error);
            } else {
                this.errmsg = '';
                var self = this;
                this.helptxt = true;
                this.error = {};
                axios({
                    method: 'post',
                    url: '/admin/auth/resetPassword',
                    data: { email: this.resetemail },
                })
                    .then(function () {
                        self.close();
                        self.$toast.add({ severity: 'success', summary: '비밀번호가 성공적으로 변경되었습니다', detail: ` 새로운 비밀번호가 ${self.resetemail}로 발송되었습니다  `, life: 5000 });
                    })
                    .catch((err) => {
                        this.helptxt = false;
                        this.errmsg = err.response.data.message;
                    });
            }
        },
        goDashboard() {
            window.location = '/';
        },
    },
};
</script>

<style scoped>
.colorchange:focus {
    background-color: #fff;
    color: blue;
}

.p-invalidusername {
    border: 1px solid red !important;
}

/* input#username {
    max-width: 70%;
}

.login-body .login-wrapper .login-panel>div {
    max-width: 295px;
    width: 85%;
} */
</style>
