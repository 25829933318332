import validator from 'validator';

const validateForget = (data) => {
  let errors = {}

  const {email} = data;

  if(!validator.isEmail(email)) {
    errors.email = '잘못된 이메일 주소입니다.'
  } 

  if(validator.isEmpty(email)) {
    errors.email = '이메일은 필수 항목입니다.'
  }

  return {
    isInvalid: Object.keys(errors).length > 0,
    error: errors
  }
}

export default validateForget;