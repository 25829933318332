import axios from 'axios';
//samsung
export default class PasswordService {
    async changePassword( newp,current) {
        return await axios.post(`/asnhist-dev/api/v1/admin/auth/change-password`, {
            newPassword: newp,
            confirmNewPassword: newp,
            currentPassword: current,
        }).then((res) => res)
    }
    async editInfos(uname,phone_number) {
        return await axios.post(`/asnhist-dev/api/v1/admin/auth/account-information-update`, {
            name: uname,
            phone_number: phone_number,
        }).then((res) => res)
    }

    async resetPassword( newp, email, code) {
        return await axios.post(`/admin/user/reset-password`, {
            password: newp,
            email: email,
            code: code,
        }).then((res) => res)
    }


}
