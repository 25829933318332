import axios from 'axios';

export default class NoticeService {
 //Notification

 async notification() {
    return await axios.post(`asnhist-dev/api/v1/admin/notification`)
      .then((res) => res)
      .catch((err) => err)
  }

  async noticeRead(_id) {
    return await axios.post(`asnhist-dev/api/v1/admin/notification/read`, { id : _id})
      .then((res) => res)
      .catch((err) => err)
  }
}