<template>
    <div ></div>
</template>

<script>

export default {
   name: "AutoLogout",

   data: function () {
    return {
        events: ['click','mousemove','scroll','keypress','load'],

        warningTimer: null,
        logoutTimer: null,
        warningZone: false,
    }
   },

   mounted() {
    this.events.forEach(function (event) {
        window.addEventListener(event, this.resetTimer)
    }, this);

    this.setTimers();
   },

   unmounted() {
    this.events.forEach(function (event) {
        window.removeEventListener(event, this.resetTimer)
    }, this);

    this.resetTimer();
   },

   methods: {

    setTimers: function () {
        // this.warningTimer = setTimeout(this.warningMessage, 4 * 1000);
        this.logoutTimer = setTimeout(this.logoutUser, 60 * 60 * 1000);
        this.warningZone = false;
    },

    // warningMessage: function () {
    //     this.warningZone = true;
    // },

    logoutUser: function () {
        localStorage.clear();
            this.$router.push({ name: 'login' });
    },

    resetTimer: function () {
        clearTimeout(this.warningTimer);
        clearTimeout(this.logoutTimer);
        this.setTimers();
    }
   }
}
</script>

<style scoped>

</style>
