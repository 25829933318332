<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <a  class="logo">
                    <h3 style="color: #002; line-height: 0.5em">{{$t('ForgetHeading')}}</h3>
                    <p>{{$t('ForgetPassText')}}</p>
                </a>
                <InputText :class="`${error.email ? 'p-invalidusername' : ''}`" id="email" placeholder="이메일 입력" v-model="email" />
                <div class="text-redx">{{ error.email }}</div>
                <!-- <div class="text-redx">{{ email_err }}</div> -->

                <Button label="확인" type="button" @click="Login" class="colorchange"></Button>
                <!-- <a href="#" @click="open">{{ $t('Forget Password') }}</a> -->
                <!-- <a href="#">forget dfc password?</a> -->
                <!-- <p>Don’t you have an account, <a href="#">sign up</a></p> -->
            </div>
        </div>
    </div>
</template>

<script>
// import validateLogin from '../validations/validateLogin';
import validateForget from '../validations/validateForget';
// import validateReset from '../validations/validateReset';
import axios from 'axios';
export default {
    data() {
        return {
            errmsg: '',
            helptxt: true,
            resetemail: '',
            display: false,
            email_err: '',
            pass_err: '',
            // email: '',
            displayBasic: false,

            error: {},
            errors: {},
            remember: true,
            email: '',
            language:'ko'
        };
    },
    mounted() {},
    methods: {
        
        rememberMe() {
            localStorage.setItem('save', this.remember);
            if (this.remember == true) {
                localStorage.setItem('email', this.email);
                localStorage.setItem('Upass', this.password);
            } else {
                localStorage.removeItem('Uid');
                localStorage.removeItem('Upass');
            }
        },

        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        async Login() {
            localStorage.setItem('email', this.email);

            let credentials = {
                email: this.email,
                // language : this.language,
            };

            const { isInvalid, error } = validateForget(credentials);
            if (isInvalid) {
                this.error = error;
                console.log(error);
            } else {
                var self = this;
                this.error = {};
                // console.log('pass');
                await axios({
                    method: 'post',
                    url: 'asnhist-dev/api/v1/admin/auth/forgot-password',
                    data: { email: this.email },
                })
                    .then(function (response) {
                        if (response.data.success === true) {
                            // localStorage.setItem('otp', response.data.otp);

                            self.$confirm.require({
                                message: '귀하의 이메일로 보내드릴 임시 비밀번호를 확인하십시오.',
                                // icon: 'pi pi-exclamation-triangle',
                                header: '발송 완료',
                                acceptLabel: '확인',
                                rejectLabel: '취소',
                                accept: () => {
                                    self.$toast.add({ severity: 'info', summary: '확인', detail: '비밀번호가 성공적으로 전송되었습니다.', life: 3000 });
                                    setTimeout(() => {
                                        self.$router.push({ name: 'login' });
                                    }, 1000);
                                },
                                reject: () => {
                                    self.$toast.add({ severity: 'error', summary: '거부됨', detail: '당신은 거부', life: 3000 });
                                },
                            });
                        }
                    })
                    .catch(() => {
                        self.$toast.add({ severity: 'info', summary: '확인', detail: "사용자가 존재하지 않습니다.", life: 3000 });
                        });
                    
            }
        },

        goDashboard() {
            window.location = '/';
        },
    },
};
</script>
<style scoped>
.colorchange:focus {
    background-color: #fff;
    color: blue;
}

.p-invalidusername{
    border: 1px solid red !important;
}
</style>