<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                    <i class="pi pi-bars"></i>
                </a>
                <router-link id="logo-link" class="layout-topbar-logo" to="/">
                    <img src="../src/assets/layout/images/logo-asian-story_2.svg" style="height: 30px; margin: -10px 0px 0px -10px" alt="AsianStory" />

                    <!-- <img :src="'assets/layout/images/logo-' + (topbarTheme === 'dark' ? 'freya-white' : 'freya') + '.svg'" alt="freya-layout" /> -->
                </router-link>
            </div>

            <AppMenu
                :layoutMode="layoutMode"
                :sidebarActive="sidebarActive"
                :sidebarStatic="sidebarStatic"
                :menuActive="menuActive"
                :mobileMenuActive="mobileMenuActive"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @sidebar-mouse-over="onSidebarMouseOver"
                @toggle-menu="onToggleMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <div class="layout-topbar-right">
                <ul class="layout-topbar-actions">
                    <!-- <li>
                        <LanguageInput />
                    </li> -->
                    <li id="bell">
                        <div class="notifys-adm">
                            <div class="igs" @click="displayNotification" ref="noticeDropDown">
                                <i class="pi pi-bell" id="bells"></i>
                                <span class="dots" v-show="isNoticeAlert"></span>
                            </div>
                            <div :class="['drops-notify', notificationList.length > 6 ? 'max-hight' : '']" v-if="show">
                                <ul>
                                    <li v-for="(notice, index) in notificationList" :key="index">
                                        <router-link :to="notificationLink(notice.notification_type)" @click="changeNoticeReadStatus(notice.id)">
                                            <p>{{ notice.notification_title }}</p>
                                            <div class="dt-tim">
                                                <span>{{ dateTimeFormat(notice.created_at).date }}</span
                                                ><span>{{ dateTimeFormat(notice.created_at).time }}</span>
                                            </div>
                                        </router-link>
                                    </li>
                                    <!-- <li>
                                        <a href="#">
                                            <p>Lorem ipsum dolor sit amet consectetur. Varius orci ac fames fusce diam.</p>
                                            <div class="dt-tim"><span>2023-02-01</span><span>04:23 PM</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <p>Lorem ipsum dolor sit amet consectetur. Varius orci ac fames fusce diam.</p>
                                            <div class="dt-tim"><span>2023-02-01</span><span>04:23 PM</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <p>Lorem ipsum dolor sit amet consectetur. Varius orci ac fames fusce diam.</p>
                                            <div class="dt-tim"><span>2023-02-01</span><span>04:23 PM</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <p>Lorem ipsum dolor sit amet consectetur. Varius orci ac fames fusce diam.</p>
                                            <div class="dt-tim"><span>2023-02-01</span><span>04:23 PM</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <p>Lorem ipsum dolor sit amet consectetur. Varius orci ac fames fusce diam.</p>
                                            <div class="dt-tim"><span>2023-02-01</span><span>04:23 PM</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <p>Lorem ipsum dolor sit amet consectetur. Varius orci ac fames fusce diam.</p>
                                            <div class="dt-tim"><span>2023-02-01</span><span>04:23 PM</span></div>
                                        </a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <h6 style="margin-top: 11px">{{ admin_type }}</h6>
                    </li>
                    <!-- <li ref="search" class="topbar-item search-item" :class="{ 'active-topmenuitem': searchActive }">
                        <a tabindex="0" @click="onTopbarSearchToggle">
                            <i class="topbar-icon pi pi-search"></i>
                        </a>

                        <div class="search-input-wrapper" @click="onTopbarSearchClick">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <InputText ref="desktopInput" type="text" placeholder="Search..." @keydown="onInputKeydown" />
                            </span>
                        </div>

                        <ul class="fadeInDown">
                            <div class="search-input-wrapper p-fluid" style="width: 100%" @click="onTopbarSearchClick">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <InputText ref="phoneInput" type="text" placeholder="Search..." @keydown="onInputKeydown" />
                                </span>
                            </div>
                        </ul>
                    </li>-->

                    <li ref="profile" class="topbar-item user-profile" :class="{ 'active-topmenuitem fadeInDown': topbarUserMenuActive }">
                        <a @click="onTopbarUserMenuClick">
                            <img v-bind:src="adminicon" alt="user" />
                        </a>
                        <ul class="fadeInDown" v-show="render">
                            <!-- <li v-show="render" @click="personalinfo">
                                <div>
                                    <span>{{$t('dfc_user.personal_info')}}</span>
                                </div>
                            </li> -->
                            <li v-show="render" @click="personalinfo" :style="$route.path == '/account-info' ? 'background-color: blue;color:#fff;' : 'background-color: none;'">
                                <div>
                                    <span>{{ $t('button.AccountInfo') }}</span>
                                </div>
                            </li>
                            <!-- <li @click="changepass">
                                <div>
                                    <span>{{$t('Change Password')}}</span>
                                </div>
                            </li>
                            <li @click="logout">
                                <div>
                                    <span>{{$t('button.logout')}}</span>
                                </div> -->
                            <li @click="changepass" :style="$route.path == '/change-pass' ? 'background-color: blue;color:#fff;' : 'background-color: none;'">
                                <div>
                                    <span>{{ $t('button.ChangePassword') }}</span>
                                </div>
                            </li>
                            <li @click="logout">
                                <div>
                                    <span>{{ $t('button.logout') }}</span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>

                <!-- <a href="#" tabindex="0" class="layout-rightpanel-button" @click="onRightMenuButtonClick($event)">
                    <i class="pi pi-arrow-left"></i>
                </a>-->
            </div>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu';
import NoticeService from './service/notificationService';
import koreanFormat from './mixins/koreanFormat';
// import LanguageInput from './components/LanguageInput.vue';
export default {
    name: 'AppTopbar',
    mixins:[koreanFormat],
    data() {
        return {
            adminicon: '/assets/layout/images/user.png',
            admin_type: localStorage.getItem('UserName'),
            admin: localStorage.getItem('userType'),
            render: false,
            show: false,
            notificationList: [],
            isNoticeAlert: false,
        };
    },
    emits: [
        'menu-click',
        'menuitem-click',
        'root-menuitem-click',
        'menu-button-click',
        'toggle-menu',
        'right-menubutton-click',
        'sidebar-mouse-over',
        'sidebar-mouse-leave',
        'topbar-search-toggle',
        'topbar-search-click',
        'topbar-search-hide',
        'topbar-usermenu-click',
        'update:searchClick',
    ],
    props: {
        searchActive: Boolean,
        searchClick: Boolean,
        topbarItemClick: Boolean,
        topbarUserMenuActive: Boolean,
        topbarUserMenuClick: Boolean,
        activeTopbarItem: String,
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        topbarTheme: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
    },
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    created() {
        this.noticeService = new NoticeService();
    },
    beforeMount() {
        window.removeEventListener('click', this.noticeClickEventListner);
    },
    mounted() {
        if (this.admin_type) {
            this.getNotifications();
            window.addEventListener('click', this.noticeClickEventListner);
        }
    },
    methods: {
        getNotifications() {
            this.noticeService
                .notification()
                .then((res) => {
                    console.log('notifications', res);
                    if (res.status == 200) {
                        this.notificationList = res.data.data.notification;
                        //this.notificationList = []
                        console.log('notification', this.notificationList.length);
                        this.isNoticeAlert = this.notificationList.length > 0 ? true : false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // alert(error)
                });
        },
        displayNotification() {
            if (this.notificationList.length > 0) {
                this.show = !this.show;
                //this.isNoticeAlert = false;
            } else {
                this.show = false;
            }
        },
        changeNoticeReadStatus(id) {
            this.noticeService.noticeRead(id).then((res) => {
                console.log('notice-read', res);
                if (res.status == 200) {
                    this.show = false;
                    this.getNotifications();
                }
            });
        },
        notificationLink(type) {
            let link;
            if (type == 'inquiry_post') {
                link = 'Inquirylist';
            }
            if (type == 'model_user_request') {
                link = 'ModelUserList';
            }
            if (type == 'max_applicants') {
                link = 'Votemanagementlist';
            }
            if (type == 'applicant_shortlisted') {
                link = 'Votemanagementlist';
            }
            // if (type == 'voting_start' || type == 'voting_end') {
            //     link = 'MainVoteListing';
            // }

            return { name: link };
        },
        // formatAMPM(d) {
        //     if (!d) return;
        //     let date = new Date(d);
        //     let hours = date.getHours();
        //     let minutes = date.getMinutes();
        //     let ampm = hours >= 12 ? 'pm' : 'am';
        //     hours = hours % 12;
        //     hours = hours ? hours : 12; // the hour '0' should be '12'
        //     minutes = minutes < 10 ? '0' + minutes : minutes;
        //     let month = date.getMonth() + 1;
        //     month = month < 10 ? '0' + month : month;
        //     let day = date.getDate();
        //     day = day < 10 ? '0' + day : day;
        //     let strDate = date.getFullYear() + '-' + month + '-' + day;
        //     let strTime = hours + ':' + minutes + ' ' + ampm;
        //     return {
        //         date: strDate,
        //         time: strTime,
        //     };
        // },
        noticeClickEventListner(e) {
            if (!this.$refs.noticeDropDown) return;

            if (e.target == this.$refs.noticeDropDown || e.composedPath().includes(this.$refs.noticeDropDown)) {
                //console.log("same-element", this.$refs.noticeDropDown)
                return;
            }

            //console.log("ouside-element", e.target)
            this.show = false;
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
        onMenuButtonClick(event) {
            this.$emit('menu-button-click', event);
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onTopbarSearchToggle(event) {
            this.$emit('topbar-search-toggle', event);
            this.onSearchFocus();
        },
        onTopbarSearchClick(event) {
            this.$emit('topbar-search-click', event);
        },

        onInputKeydown(event) {
            const key = event.which;

            //escape, tab and enter
            if (key === 27 || key === 9 || key === 13) {
                this.$emit('topbar-search-hide', event);
            }
        },
        onTopbarUserMenuClick(event) {
            this.render = this.admin == 'admin' ? false : true;
            this.$emit('topbar-usermenu-click', event);
        },
        onRightMenuButtonClick(event) {
            this.$emit('right-menubutton-click', event);
        },
        onSidebarMouseOver() {
            this.$emit('sidebar-mouse-over');
        },
        onSidebarMouseLeave() {
            this.$emit('sidebar-mouse-leave');
        },
        logout() {
            // console.log('logout method clicked');
            localStorage.clear();
            this.$router.push({ name: 'login' });
        },
        changepass() {
            this.$router.push({ name: 'ChangePass' });
            this.render = false;
        },
        personalinfo() {
            this.$router.push({ name: 'AccountInfo' });
            this.render = false;
        },

        uploadDoc() {
            this.$router.push({ name: 'UploadedDoclist' });
        },
        onSearchFocus() {
            if (window.innerWidth >= 576) {
                this.$refs.desktopInput.$el.focus();
            } else {
                this.$nextTick(function () {
                    this.$refs.phoneInput.$el.focus();
                });
            }
        },
    },
    components: {
        AppMenu,
        // LanguageInput,
    },
};
</script>
