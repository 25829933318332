<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active': sidebarActive }" @click="onMenuClick"
        @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img v-show="rend2" src="../src/assets/layout/images/icon.svg" style="height: 35px; width: auto"
                    alt="ASIAN STORY" />
                <img v-show="rend" src="../src/assets/layout/images/logo-asian-story_3.svg" alt="ASIAN STORY"
                    style="width: auto; height: 50px" />
                <!-- <b style="padding-left: 15px; font-size: 23px;color:#728FCE">{{ title }}</b> -->
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <span v-if="usertype == 'super_admin'">
                <AppSubmenu class="layout-menu" :items="asian_history_menu" :layoutMode="layoutMode"
                    :parentMenuItemActive="true" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive"
                    :root="true" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
            </span>
            <span v-else-if="usertype == 'manager'">
                <AppSubmenu class="layout-menu" :items="asian_history_menu_manager" :layoutMode="layoutMode"
                    :parentMenuItemActive="true" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive"
                    :root="true" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
            </span>
        </div>
    </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';
export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'toggle-menu', 'sidebar-mouse-over', 'sidebar-mouse-leave'],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
    },
    data() {
        return {
            logo1: '../src/assets/layout/images/logo-symbol.svg',
            logonutrabit: '../src/assets/layout/images/logo-asian-story_2.svg',
            rend: false,
            rend2: true,

            asian_history_menu: [
                { label: this.$t(`module.title.Dashboard`), icon: 'pi pi-th-large', to: '/dashboard' },
                { label: this.$t(`module.title.manager_management`), icon: 'pi pi-cog', to: '/ManagerManagementlist' },
                { label: this.$t(`module.title.Vote_management`), icon: 'pi pi-id-card', to: '/Votemanagementlist' },
                // {
                //     label: this.$t(`module.title.Bulletin_management`),
                //     icon: 'pi pi-bell',
                //     items: [
                //         { label: this.$t(`module.title.BulletinList`), icon: 'pi pi-table', to: '/BulletinManagementList' },
                //         // { label: this.$t(`module.title.BulletinCategoryManagement`), icon:'pi pi-table', to: '/BulletinCategoryManagementlist' },
                //     ],
                // },
                { label: this.$t(`module.title.BulletinList`), icon: 'pi pi-table', to: '/BulletinManagementList' },
                { label: this.$t(`module.title.Free_Board_Management`), icon: 'pi pi-book', to: '/FreeBoardManagementlist' },
                {
                    label: this.$t(`module.title.User_management`),
                    icon: 'pi pi-users',
                    items: [
                        { label: this.$t(`module.title.General_User_Management`), icon: 'pi pi-user', to: '/GeneralUser/GeneralUserList' },
                        { label: this.$t(`module.title.Model_User_Management`), icon: 'pi pi-user', to: '/ModelUser/ModelUserList' },
                    ],
                },
                // { label: this.$t(`module.title.User_management`), icon: 'pi pi-users', to: '/dashboard' },
                { label: this.$t(`module.title.Inquiry`), icon: 'pi pi-comments', to: '/Inquiry/Inquirylist' },
                // { label: this.$t(`module.title.Client-lookup`), icon: 'pi pi-users', to: '/client-lookup' },
            ],

            asian_history_menu_manager: [
                { label: this.$t(`module.title.Dashboard`), icon: 'pi pi-th-large', to: '/dashboard' },

                { label: this.$t(`module.title.Manager_Applicationlist`), icon: 'pi pi-id-card', to: '/ManagerApplicationlist' },
                { label: this.$t(`module.title.Inquiry`), icon: 'pi pi-comments', to: '/Manager/Inquiry/Inquirylist' },
                // { label: this.$t(`module.title.Client-lookup`), icon: 'pi pi-users', to: '/client-lookup' },
            ],
            menu: [
                // onprogress ==> file not ready , when the file ll be ready change the path
                {
                    label: 'UI Kit',
                    icon: 'pi pi-star-o',
                    items: [
                        { label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout' },
                        { label: 'Input', icon: 'pi pi-check-square', to: '/input' },
                        { label: 'Float Label', icon: 'pi pi-bookmark', to: '/floatlabel' },
                        { label: 'Invalid State', icon: 'pi pi-exclamation-circle', to: '/invalidstate' },
                        { label: 'Button', icon: 'pi pi-mobile', to: '/button', class: 'rotated-icon' },
                        { label: 'Table', icon: 'pi pi-table', to: '/table' },
                        { label: 'List', icon: 'pi pi-list', to: '/list' },
                        { label: 'Tree', icon: 'pi pi-share-alt', to: '/tree' },
                        { label: 'Panel', icon: 'pi pi-tablet', to: '/panel' },
                        { label: 'Overlay', icon: 'pi pi-clone', to: '/overlay' },
                        { label: 'Media', icon: 'pi pi-image', to: '/media' },
                        { label: 'Menu', icon: 'pi pi-bars', to: '/menu' },
                        { label: 'Message', icon: 'pi pi-comment', to: '/messages' },
                        { label: 'File', icon: 'pi pi-file', to: '/file' },
                        { label: 'Chart', icon: 'pi pi-chart-bar', to: '/chart' },
                        { label: 'Misc', icon: 'pi pi-circle-off', to: '/misc' },
                    ],
                },
                {
                    label: 'Utilities',
                    icon: 'pi pi-compass',
                    items: [
                        { label: 'Display', icon: 'pi pi-desktop', to: '/display' },
                        { label: 'Elevation', icon: 'pi pi-external-link', to: '/elevation' },
                        { label: 'Flexbox', icon: 'pi pi-directions', to: '/flexbox' },
                        { label: 'Icons', icon: 'pi pi-search', to: '/icons' },
                        { label: 'Text', icon: 'pi pi-pencil', to: '/text' },
                        { label: 'Widgets', icon: 'pi pi-star-o', to: '/widgets' },
                        { label: 'Grid System', icon: 'pi pi-th-large', to: '/grid' },
                        { label: 'Spacing', icon: 'pi pi-arrow-right', to: '/spacing' },
                        { label: 'Typography', icon: 'pi pi-align-center', to: '/typography' },
                    ],
                },
                {
                    label: 'Pages',
                    icon: 'pi pi-briefcase',
                    items: [
                        { label: 'Crud', icon: 'pi pi-pencil', to: '/crud' },
                        { label: 'Calendar', icon: 'pi pi-calendar-plus', to: '/calendar' },
                        { label: 'Timeline', icon: 'pi pi-calendar', to: '/timeline' },
                        { label: 'Landing', icon: 'pi pi-globe', url: 'assets/pages/landing.html', target: '_blank' },
                        { label: 'Login', icon: 'pi pi-sign-in', to: '/login' },
                        { label: 'Invoice', icon: 'pi pi-dollar', to: '/invoice' },
                        { label: 'Help', icon: 'pi pi-question-circle', to: '/help' },
                        { label: 'Error', icon: 'pi pi-times-circle', to: '/error' },
                        { label: 'Not Found', icon: 'pi pi-exclamation-circle', to: '/notfound' },
                        { label: 'Access Denied', icon: 'pi pi-lock', to: '/access' },
                        { label: 'Empty', icon: 'pi pi-circle-off', to: '/empty' },
                    ],
                },
                {
                    label: 'Hierarchy',
                    icon: 'pi pi-align-left',
                    items: [
                        {
                            label: 'Submenu 1',
                            icon: 'pi pi-align-left',
                            items: [
                                {
                                    label: 'Submenu 1.1',
                                    icon: 'pi pi-align-left',
                                    items: [
                                        { label: 'Submenu 1.1.1', icon: 'pi pi-align-left' },
                                        { label: 'Submenu 1.1.2', icon: 'pi pi-align-left' },
                                        { label: 'Submenu 1.1.3', icon: 'pi pi-align-left' },
                                    ],
                                },
                                {
                                    label: 'Submenu 1.2',
                                    icon: 'pi pi-align-left',
                                    items: [{ label: 'Submenu 1.2.1', icon: 'pi pi-align-left' }],
                                },
                            ],
                        },
                        {
                            label: 'Submenu 2',
                            icon: 'pi pi-align-left',
                            items: [
                                {
                                    label: 'Submenu 2.1',
                                    icon: 'pi pi-align-left',
                                    items: [
                                        { label: 'Submenu 2.1.1', icon: 'pi pi-align-left' },
                                        { label: 'Submenu 2.1.2', icon: 'pi pi-align-left' },
                                    ],
                                },
                                {
                                    label: 'Submenu 2.2',
                                    icon: 'pi pi-align-left',
                                    items: [{ label: 'Submenu 2.2.1', icon: 'pi pi-align-left' }],
                                },
                            ],
                        },
                    ],
                },
                {
                    label: 'Start',
                    icon: 'pi pi-download',
                    items: [
                        { label: 'Buy Now', icon: 'pi pi-shopping-cart', command: () => window.open('https://www.primefaces.org/store', '_blank') },
                        { label: 'Documentation', icon: 'pi pi-info-circle', to: '/documentation' },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.usertype = localStorage.getItem('userType');
    },
    methods: {
        onSidebarMouseOver() {
            if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
                this.$emit('sidebar-mouse-over');
                this.rend = true;
                this.rend2 = false;
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit('sidebar-mouse-leave');
                    this.rend = false;
                    this.rend2 = true;
                }, 250);
            }
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
    components: { AppSubmenu },
};
</script>
<style scoped>
/* .layout-menu-light .menu-wrapper{
    background: #666666;
   
} */
</style>